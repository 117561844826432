@import url('https://fonts.googleapis.com/css2?family=Changa&family=Comfortaa&family=Crimson+Pro&family=Dancing+Script&family=Dosis&family=EB+Garamond&family=Exo&family=Exo+2&family=Faustina&family=Fira+Code&family=Heebo&family=Hepta+Slab&family=Inconsolata&family=Inter&family=Josefin+Sans&family=Jost&family=Jura&family=Kreon&family=Lemonada&family=Literata&family=Lora&family=Manrope&family=Manuale&family=Manuale&family=Makrazi+Text&family=Maven+Pro&family=Muli&family=Orbitron&family=Oswald&family=Playfair+Display&family=Podkova&family=Public+Sans&family=Quicksand&family=Roboto+Slab&family=Rokkitt&family=Rosario&family=Ruda&family=Spartan&family=Vollkorn&family=Work+Sans&family=Yanone+Kaffeesatz&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

@font-face {
  font-family: "MyriadPro";
  src: url("./_app/assets/fonts/MyriadPro-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "ExpletusSans";
  src: url("./_app/assets/fonts/ExpletusSans-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Ginger";
  src: url("./_app/assets/fonts/Ginger-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "Ginger Display Web";
  src: url("./_app/assets/fonts/Ginger-Regular-F37-Web.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "VUITY Archia";
  src: url("./_app/assets/fonts/VUITYArchia-Regular.otf");
  font-weight: normal;
}
