.ck-content figure.image-style-align-left {
  float: unset;
  margin-right: auto;
  margin-left: 0;
}

.ck-content figure.image-style-align-right {
  float: unset;
  margin-left: auto;
  margin-right: 0;
}

.ck-content figure.table {
  float: unset !important;
  margin-right: auto;
  margin-left: 0;
}
/* hide table alignment property - defaults to left*/
.ck-table-properties-form__alignment-row {
  display: none !important;
}

body .ck-content .table table, body .ck-content .table td {
  border: none;
}
